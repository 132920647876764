<template>
  <div class="ui-authentication">
    <v-block :background="true" :border="false" :shadow="true">
      <v-block-content>
        <v-title class="margin-bottom-20">Login</v-title>
        <form autocomplete="off" @submit.prevent="formSubmit()">
          <div class="grid-x">
            <div class="cell">
              <v-input
                v-model="form.company"
                icon="business"
                placeholder="Empresa"
                :autofocus="true"
                :error="form.$errors.has('company')"
                :description="form.$errors.first('company')"
              />
            </div>
            <div class="cell">
              <v-input
                v-model="form.username"
                icon="account_circle"
                placeholder="Usuário"
                :error="form.$errors.has('username')"
                :description="form.$errors.first('username')"
              />
            </div>
            <div class="cell">
              <v-input
                v-model="form.password"
                icon="lock"
                placeholder="Senha"
                type="password"
                :error="form.$errors.has('password')"
                :description="form.$errors.first('password')"
              />
            </div>
            <div class="cell">
              <v-button type="submit" value="Entrar" :loading="processing"/>
            </div>
          </div>
        </form>
      </v-block-content>
    </v-block>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import { Form } from "@nulvem/js-form";

import VTitle from "@/components/commons/VTitle";
import VBlock from "@/components/blocks/VBlock";
import VBlockContent from "@/components/blocks/VBlockContent";
import VButton from "@/components/forms/VButton";
import VInput from "@/components/forms/VInput";

export default {
  name: "UiAuthentication",

  data() {
    return {
      processing: false,
      form: new Form({
        company: {
          value: null,
          validation: {
            rules: "required",
            messages: {
              required: "Informe o nome da empresa",
            },
          },
        },
        username: {
          value: null,
          validation: {
            rules: "required",
            messages: {
              required: "Informe o nome do usuário",
            },
          },
        },
        password: {
          value: null,
          validation: {
            rules: "required",
            messages: {
              required: "Informe a sua senha",
            },
          },
        },
      }),
    };
  },

  methods: {
    ...mapActions({
      authenticateUserAction: "auth/authenticateUser",
    }),
    formSubmit() {
      if (this.processing) return;

      this.processing = true;

      this.form.$errors.clear();

      this.form
        .$validate()
        .then(() => {
          this.authenticateUserAction(this.form.$values()).then(() => {
            this.processing = false;
          });
        })
        .catch(() => {
          this.processing = false;
          this.$n(
            "É necessário preencher todos os campos obrigatórios",
            "error"
          );
        });
    },
  },

  components: {
    VTitle,
    VBlock,
    VBlockContent,
    VButton,
    VInput,
  },
};
</script>
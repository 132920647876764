<template>
  <v-base class="home">
    <div class="grid-container">
      <div class="grid-x align-middle">
        <div class="cell large-6 medium-6">
          <div class="description">
            O Carteiro Digital é a forma mais confiável, instantânea e segura para envio de boletos e documentos
            digitais.
          </div>
        </div>
        <div class="cell large-4 large-offset-2 medium-5 medium-offset-1 small-12">
          <ui-authentication/>
        </div>
      </div>
    </div>
  </v-base>
</template>

<script>
import VBase from "@/views/layouts/VBase";
import VTitle from "@/components/commons/VTitle";

import UiAuthentication from "@/views/pages/home/components/UiAuthentication";

export default {
  name: 'Home',
  components: {
    VBase,
    VTitle,
    UiAuthentication
  }
}
</script>

<style lang="scss" scoped>
@import "../../../assets/scss/foundation";

.home {
  background-image: url("~whiteLabel/images/home-background.jpg");
  background-size: cover;
  background-position: center;
  height: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  & /deep/ .page-content {
    display: flex;
    align-items: center;
    height: 100%;
  }

  .description {
    color: #fff;
    font-size: 45px;
    font-weight: 400;
    line-height: 60px;
  }

  @include breakpoint(small only) {
    & /deep/ .page-content {
      padding: 60px 0;
    }

    .description {
      display: none;
    }
  }

  @include breakpoint(medium only) {
    .description {
      font-size: 35px;
      line-height: 50px;
    }
  }
}
</style>
